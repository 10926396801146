<template>
  <base-view title="Dirección de Planeación - Reporte de Saldos por Partida">
    <div class="container-fluid mt-4">
      <div class="card">
        <div class="card-body">
          <reporte-planeacion-saldos-partidas/>
        </div>
      </div>
    </div>
  </base-view>
</template>

<script>
import ReportePlaneacionSaldosPartidas from '@/components/Reportes/ReportePlaneacionSaldosPartidas'

export default {
  name: 'ReportePlaneacionSaldosPartidasView',

  components: {
    ReportePlaneacionSaldosPartidas
  }
}
</script>
